import { findOne, hasParent } from 'Shared/Helper/Dom/Dom';
import { Dialog } from 'Shared/Component/Dialog/Dialog';

const NAME = 'external-listing-contact-dialog';

const MESSAGE_HANDLERS = [
    'showExternalDialogClick.all',
];

class ExternalListingContactDialog extends Dialog {
    initialize () {
        this.bindMethodsToSelf([
            'onShowExternalDialogClick',
            'onBodyClick',
            'onSubmit',
        ]);

        super.initialize();
    }

    connect () {
        super.connect();

        const form = findOne('form', this.element);
        form.addEventListener('submit', this.onSubmit);
    }

    onBodyClick (evt) {
        if (!hasParent(evt.target, this.element)) {
            this.close();
        }
    }

    onSubmit () {
        this.close();
    }

    onCancelClick () {
        this.close();
    }

    close () {
        this.element.close();
        document.body.removeEventListener('click', this.onBodyClick);
        document.body.classList.remove('page--dialog-open');
    }

    onShowExternalDialogClick () {
        this.element.showModal();
        document.body.addEventListener('click', this.onBodyClick);
        document.body.classList.add('page--dialog-open');
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': ExternalListingContactDialog,
};
