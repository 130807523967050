import { listingDetailControlsControllerFactory } from 'Shared/Component/ListingDetailControls/ListingDetailControlsControllerFactory';
import { ComponentController } from 'Huurwoningen/Helper/Stimulus/ComponentController';

const NAME = 'listing-detail-controls';

const SharedListingDetailControlsController = listingDetailControlsControllerFactory({
    'componentController': ComponentController,
});

export default {
    'name': NAME,
    'controller': SharedListingDetailControlsController,
};
