import mailAlertFromSearch from 'Shared/Component/Dialog/MailAlertFromSearch/MailAlertFromSearch';

class MailAlertFromSearch extends mailAlertFromSearch.controller {
    onMailAlertButtonClicked () {
        super.onMailAlertButtonClicked();

        navigator.sendBeacon('/api/track/property-alert-subscription-start');
    }
}

export default {
    'name': mailAlertFromSearch.name,
    'controller': MailAlertFromSearch,
};
